import * as PropTypes from "prop-types";

import CTAButton from "~/components/CTAButton";
import RichText from "~/components/RichText";

const Comparison = function ({ slice }) {
  const buttonSize = "small";

  const headingColorClasses = function (color) {
    return `bg-${color} text-white`;
  };

  return (
    <div className="grid-cols-3 gap-8 lg:grid">
      {slice.primary.rich_text && (
        <div className="rte">
          <RichText field={slice.primary.rich_text} />
        </div>
      )}
      {slice.items.map((item, index) => {
        return (
          <div className="comparison-item" key={index}>
            <h3
              className={`comparison-item-heading ${headingColorClasses(
                item.heading_color
              )}`}
            >
              <div className="comparison-item-heading-text">{item.heading}</div>
              <div className="comparison-item-heading-price">{item.price}</div>
            </h3>

            <div className="comparison-item-body">
              <RichText field={item.rich_text} />
            </div>

            {item.cta_text && (
              <div className="comparison-actions">
                <CTAButton
                  field={item}
                  className={`button button-${buttonSize} ${item.cta_classes || null
                    }`}
                >
                  {item.cta_text}
                </CTAButton>
              </div>
            )}
          </div>
        );
      })}
    </div>
  );
};

Comparison.propTypes = {
  slice: PropTypes.object.isRequired,
};

export default Comparison;
